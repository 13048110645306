import React, { useState, useEffect } from 'react';
import './Resume.scss';
import { images } from '../../constants';
import { urlFor, client } from '../../client';
import { AppWrap, MotionWrap } from '../../wrapper';

 const Resume = () => {
    const [resume, setResume] = useState([]);
    useEffect(() => {
        const query = '*[_type == "resume"]';
    
        client.fetch(query).then((data) => {
          setResume(data);
        });
      }, []);
    return(
      
      <html lang="en">
      <head>
      <link rel="icon" href="./profile.png" />
      </head>
      <body>
      <div id="Resume">
      
      <h1>Resume</h1>
     
      <div class="box">
<div class="content">
  <a target="blank" href="wwww.ganeshbabu.xyz/#resume" >
<img id="resume_img" src={images.resume}alt="resum.jpg" ontouchstart=""/></a>
{/* <object data={images.resumepdf} type="application/pdf" id="pdf">
<h1><a href={images.resumepdf}>Resume PDF!</a></h1>
</object> */}
</div>

</div>
<div>
  <table>
      <tr>
<td>
<a target="blank" class="p" href={images.resumepdf}><button class="button-35" role="button"><p class="button-txt">View👀</p></button></a>
</td>
<td>
<a target="blank" class="p" href={images.resumepdf} download><button class="button-35" role="button">Download⬇️</button></a>

</td>

      </tr>
      {/* <tr>
        <td>
        <div class="content">
<h2 class="text_shadows">hire me</h2>
</div>
        </td>
      </tr> */}
      
  </table>
</div>

      </div>
      </body>
      </html>
  
      
     
   
        
  )
}

export default AppWrap(
    MotionWrap(Resume, 'app__resume'),
    'resume',
    'app__whitebg',
  );
    


