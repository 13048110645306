import React from 'react';
import { BsTwitter, BsInstagram, BsLinkedin } from 'react-icons/bs';
import { AiFillGithub } from 'react-icons/ai';

const SocialMedia = () => (
  <div className="app__social">
    <div>
       <a target="blank" href="https://twitter.com/ganesh_babu_"><BsTwitter /></a>
    </div>
    <div>
     <a target="blank" href="https://github.com/ganesh070723"><AiFillGithub /></a>
    </div>
    <div>
      <a target="blank" href="https://www.instagram.com/ganesh.babu_/"> <BsInstagram /></a>
    </div>
    <div>
      <a target="blank" href="https://www.linkedin.com/in/ganeshbabu-karunanithi-bab160216/"><BsLinkedin/></a>
    </div>
  </div>
);

export default SocialMedia;
